require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

// require("jquery-ui/widgets/sortable")
// require("rails_sortable")

import $ from 'jquery';
import jQuery from 'jquery';
window.$ = jQuery;

import "bootstrap";
import "bootstrap-datepicker";
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.cs.js";
import "bootstrap-select";
import 'bootstrap-tokenfield';
import 'bootstrap-toggle';
import 'bootstrap-toggle/css/bootstrap-toggle.css';
import 'ekko-lightbox';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/sortable';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import 'jquery-cropper';
import "cocoon";
import "@fortawesome/fontawesome-free/js/all";



// import 'select2';
// import 'select2/dist/css/select2.css';

import 'owl-carousel/owl-carousel/owl.carousel';
import 'magnific-popup/dist/jquery.magnific-popup.min.js';
import 'magnific-popup/dist/magnific-popup.css';

import "../royal/royal.scss";
import "../royal/index.js";

// import "bootstrap-select/dist/css/bootstrap-select.min.css";

import "../stylesheets/stylesheets.scss"
// import "./public.scss";


import tinymce from 'tinymce/tinymce';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/themes/silver/theme.min.js';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';

import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';


require("packs/custom/app");



require("./custom/app");
require("./custom/custom");
// require("./custom/hello_coffee");
require("./custom/fault");
require("./custom/training");
require("./custom/row_clickability");
require("./custom/birth_date");
require("./custom/cookieconsent");
// require("./custom/test");

import Cookies from 'js.cookie';
window.Cookies = Cookies;
import "./gems.js.erb"


$(document).on('turbolinks:before-cache', function() {
  $('.user-link-popover .popover').remove();
  $('.ad-view-popover .popover').remove();
  $('.modal-dialog button.close').trigger('click');

  $('.selectpicker').selectpicker('destroy')
  $('[data-toggle="tooltip"]').tooltip('hide');
});
